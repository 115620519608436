import { Box, Button, Divider, Flex, Grid, InputWrapper, Popover, Text, TextInput } from "@mantine/core";
import { IconBrandFacebook, IconBrandInstagram, IconBrandTelegram, IconBrandTwitter, IconPhoneCall } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { UserApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { message } from "../../utils/message";
import { Form } from "../form";
import { DatePickerField } from "../form/date-picker-field";
import { ImageField } from "../form/image-field";
import { TextField } from "../form/text-field";
import { IdentityCardUpload } from "../upload/identity-card-upload";

type IFormData = {
  familyName: string | undefined;
  lastName: string | undefined;
  firstName: string | undefined;
  registerNo: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  avatar: string | undefined;
  identityCardFront: string | undefined;
  identityCardBack: string | undefined;
  departmentUnitId: string | undefined;
  departmentSubUnitId: string | undefined;
  employeeUnitId: string | undefined;
  email2: string | undefined;
  phone2: string | undefined;
  socialLinks: [
    {
      type: string | undefined;
      link: string | undefined;
    },
  ];
};

const schema = yup.object({
  familyName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!")
    .trim(),
  firstName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!")
    .trim(),
  lastName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  registerNo: yup
    .string()
    .matches(/^[А-Яа-яA-Za-z|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Зөвхөн регистр оруулна уу")
    .required("Заавал бөглөнө!")
    .trim(),
  email: yup.string().email("Email оруулна уу").required("Заавал бөглөнө!").nullable().trim(),
  phone: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .required("Заавал бөглөнө!")
    .nullable(),
  identityCardFront: yup.string().optional().nullable(),
  identityCardBack: yup.string().optional().nullable(),
  email2: yup.string().email("Email оруулна уу").nullable(),
  phone2: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .nullable(),
  departmentUnitId: yup.string().optional().nullable(),
  departmentSubUnitId: yup.string().optional().nullable(),
  avatar: yup.string().nullable().trim().optional(),
  socialLinks: yup
    .array()
    .of(
      yup.object().shape({
        link: yup.string().trim().nullable().optional(),
        type: yup.string().trim().nullable().optional(),
      }),
    )
    .nullable()
    .optional(),
});

const letters = [
  "А",
  "Б",
  "В",
  "Г",
  "Д",
  "Е",
  "Ё",
  "Ж",
  "З",
  "И",
  "Й",
  "К",
  "Л",
  "М",
  "Н",
  "О",
  "Ө",
  "П",
  "Р",
  "С",
  "Т",
  "У",
  "Ү",
  "Ф",
  "Х",
  "Ц",
  "Ч",
  "Ш",
  "Щ",
  "Ъ",
  "Ы",
  "Ь",
  "Э",
  "Ю",
  "Я",
];

export function UserForm({ action, onCancel, reload }: any) {
  const { socialTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedLetters, setSelectedLetters] = React.useState<string[]>([
    action[1]?.registerNo?.slice(0, 1) || "А",
    action[1]?.registerNo?.slice(1, 2) || "А",
  ]);
  const [number, setNumber] = React.useState<string>(action[1]?.registerNo?.slice(2) || "");
  const [openedPopover, setOpenedPopover] = React.useState<number | null>(null);

  const handleLetterSelect = (index: number, letter: string, setFieldValue: any) => {
    setSelectedLetters((prevLetters) => {
      const updatedLetters = [...prevLetters];
      updatedLetters[index] = letter;

      setFieldValue("registerNo", `${updatedLetters[0]}${updatedLetters[1]}${number}`);

      return updatedLetters;
    });
  };

  const [data] = React.useState<IFormData>({
    familyName: undefined,
    lastName: undefined,
    firstName: undefined,
    registerNo: action[1]?.registerNo || undefined,
    email: undefined,
    phone: undefined,
    avatar: undefined,
    identityCardFront: undefined,
    identityCardBack: undefined,
    departmentUnitId: undefined,
    departmentSubUnitId: undefined,
    employeeUnitId: undefined,
    email2: undefined,
    phone2: undefined,
    socialLinks: socialTypes?.map((item: any) => {
      return {
        name: item.code,
        code: item.code,
        type: item.code,
        link: "",
      };
    }),
    ...(action[1] && {
      familyName: action[1]?.familyName,
      lastName: action[1]?.lastName,
      firstName: action[1]?.firstName,
      registerNo: action[1]?.registerNo,
      email: action[1]?.email,
      phone: action[1]?.phone,
      avatar: action[1]?.avatar || "",
      identityCardFront: action[1]?.identityCardFront,
      identityCardBack: action[1]?.identityCardBack,
      departmentUnitId: action[1]?.departmentUnitId,
      departmentSubUnitId: action[1]?.departmentSubUnitId,
      employeeUnitId: action[1]?.employeeUnitId,
      email2: action[1]?.email2,
      phone2: action[1]?.phone2,
      socialLinks: socialTypes?.map((item: any) => {
        return {
          name: item?.code,
          code: item?.code,
          type: item?.code,
          link: action[1]?.socialLinks?.find((item2: any) => item2?.type === item?.code)?.link || "",
        };
      }),
    }),
  });

  const icons = (type: string) => {
    if (type === "FACEBOOK") return <IconBrandFacebook size={18} />;
    else if (type === "VIBER") return <IconPhoneCall size={18} />;
    else if (type === "INSTAGRAM") return <IconBrandInstagram size={18} />;
    else if (type === "TWITTER") return <IconBrandTwitter size={18} />;
    else if (type === "TELEGRAM") return <IconBrandTelegram size={18} />;
  };

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    if (action[0] === "edit" && action[1]) {
      try {
        await UserApi.update(action[1].id, {
          ...values,
          socialLinks: values?.socialLinks
            ?.filter((item1: any) => !!item1.link?.trim())
            ?.map((item2: any) => {
              return {
                type: item2.type,
                link: item2.link?.trim(),
              };
            }),
          avatar: undefined,
        });
        reload();
        onCancel();
        setLoading(false);
        message.success("Хэрэглэгчийн мэдээлэл амжилттай засагдлаа");
      } catch (error: any) {
        setLoading(false);
        message.error(error.message || "Хэрэглэгчийн мэдээлэл засахад алдаа гарлаа!");
      }
    } else if (action[0] === "new") {
      try {
        await UserApi.create({
          ...values,
          socialLinks: values?.socialLinks
            ?.filter((item1: any) => !!item1.link?.trim())
            ?.map((item2: any) => {
              return {
                type: item2.type,
                link: item2.link?.trim(),
              };
            }),
        });
        reload();
        onCancel();
        setLoading(false);
        message.success("Хэрэглэгч амжилттай нэмлээ");
      } catch (error: any) {
        setLoading(false);
        message.error(error.message || "Хэрэглэгч нэмэхэд алдаа гарлаа!");
      }
    }
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
      {({ values, setFieldValue, errors }) => {
        return (
          <Grid>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField name="username" placeholder="Хэрэглэгчийн нэр" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField name="registeredAdmin" placeholder="Бүртгэсэн админ" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <DatePickerField name="createdAt" placeholder="Бүртгэсэн огноо, цаг" label="Системээс авто үүсгэнэ" disabled />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField name="familyName" label="Ургийн овог" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField name="lastName" label="Эцэг, эхийн нэр" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField name="firstName" label="Өөрийн нэр" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <InputWrapper label="Регистрийн дугаар" required>
                <Flex gap="xs">
                  {selectedLetters.map((letter, index) => (
                    <Popover
                      key={index}
                      opened={openedPopover === index}
                      onClose={() => setOpenedPopover(null)}
                      onChange={(opened) => {
                        if (!opened) setOpenedPopover(null);
                      }}>
                      <Popover.Target>
                        <Button
                          style={{ width: 40, height: 36, padding: 0, border: errors?.registerNo && !values.registerNo ? `1px solid red` : `` }}
                          onClick={() => setOpenedPopover(index)}
                          variant="default">
                          {letter || "А"}
                        </Button>
                      </Popover.Target>
                      <Popover.Dropdown w={264} style={{ padding: 10 }}>
                        <Box style={{ display: "flex", alignItems: "center", gap: 10, flexWrap: "wrap" }}>
                          {letters.map((l) => (
                            <Button
                              key={l}
                              style={{ padding: 0, width: 40, height: 40 }}
                              onClick={() => {
                                handleLetterSelect(index, l, setFieldValue);
                                setOpenedPopover(null);
                              }}
                              variant={selectedLetters[index] === l ? "filled" : "default"}>
                              {l}
                            </Button>
                          ))}
                        </Box>
                      </Popover.Dropdown>
                    </Popover>
                  ))}
                  <TextInput
                    placeholder="Оруулна уу"
                    name="registerNo"
                    onChange={(e: any) => {
                      setFieldValue("registerNo", `${selectedLetters[0]}${selectedLetters[1]}${e.target.value.replace(/\D/, "")}`);
                      setNumber(e.target.value.replace(/\D/, ""));
                    }}
                    value={number}
                    styles={{ input: { borderColor: errors?.registerNo && !values.registerNo ? "red" : "" } }}
                  />
                </Flex>
                {errors?.registerNo && !values.registerNo && (
                  <Text size="xs" c="red" mt={5}>
                    {errors.registerNo}
                  </Text>
                )}
              </InputWrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField name="email" label="И-мэйл №1 (бүртгэл баталгаажуулах)" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField type="number" name="phone" label="Гар утас №1 (Бүртгэл баталгаажуулах)" placeholder="Оруулна уу" required />
            </Grid.Col>
            <Grid.Col span={12}></Grid.Col>
            {action[0] !== "edit" && (
              <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                <ImageField name="avatar" label="Профайл зураг" required={false} />
              </Grid.Col>
            )}
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <IdentityCardUpload name="identityCardFront" label="Иргэний үнэмлэх - Урд тал" required={false} />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <IdentityCardUpload name="identityCardBack" label="Иргэний үнэмлэх - Ард тал" required={false} />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField label="Харьяалах нэгж" placeholder="Харьяалах нэгж оруулна уу" name="departmentUnitId" />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField
                label="Харьяалах дэд нэгж"
                placeholder="Харьяалах дэд нэгж оруулна уу"
                name="departmentSubUnitId"
                disabled={!values.departmentUnitId}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField label="Албан тушаал" placeholder="Албан тушаал оруулна уу" name="employeeUnitId" disabled={!values.departmentSubUnitId} />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField name="email2" label="И-мэйл №2" placeholder="Оруулна уу" />
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <TextField type="number" name="phone2" label="Гар утас №2" placeholder="Оруулна уу" />
            </Grid.Col>
            <Grid.Col span={12} />
            {values?.socialLinks?.map((item: IReference, index: number) => {
              return (
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }} key={index}>
                  <TextField
                    onChangeCustom={(e: any) => {
                      setFieldValue(`socialLinks[${index}].type`, item.code);
                    }}
                    icon={icons(item.code)}
                    name={`socialLinks[${index}].link`}
                    placeholder={item.name}
                    label={item.name}
                  />
                </Grid.Col>
              );
            })}
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex justify="end" gap="md">
                <Button onClick={() => onCancel()} variant="default">
                  Буцах
                </Button>
                <Button loading={loading} type="submit">
                  Хадгалах
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
